import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from '@johnlewispartnership/wtr-ingredients/dist/Icon/ImagePlaceholder';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/ingredients/Tooltip';
import generateThumbnailImageSrc from 'utils/generate-thumbnail-image-src';
import styles from './Thumbnail.scss';

const Thumbnail = ({ disableTooltip, itemId, name, imgSize, tooltipTrigger }) => {
  const [hasImageLoaded, setImageLoaded] = useState(false);
  const src = itemId ? generateThumbnailImageSrc(itemId) : null;

  useEffect(() => {
    let isMounted = true;
    if (!src) return;

    const preFetchedImage = new Image();
    preFetchedImage.onerror = () => {
      if (isMounted) setImageLoaded(false);
    };
    preFetchedImage.onload = () => {
      if (isMounted) setImageLoaded(true);
    };
    // NOTE: To preload an image we only fetch it on the fly here but we never render this instance,
    // when React renders a new <img /> with the same src later, it will use the browser cache
    // we are not using onError prop on <img /> as it is deprecated
    preFetchedImage.src = src;
    // eslint-disable-next-line consistent-return
    return () => {
      isMounted = false;
    };
  }, [src]);

  const image = !hasImageLoaded ? (
    <span aria-label={name} role="img">
      <ImagePlaceholder className={styles.placeHolder} />
    </span>
  ) : (
    <img alt={name} src={src} height={imgSize} width={imgSize} />
  );

  if (disableTooltip) return image;

  return tooltipTrigger && hasImageLoaded ? (
    <Tooltip placement="bottom-start" pointer="down" content={name}>
      {image}
    </Tooltip>
  ) : (
    image
  );
};

Thumbnail.propTypes = {
  disableTooltip: PropTypes.bool,
  imgSize: PropTypes.number,
  itemId: PropTypes.string,
  name: PropTypes.string.isRequired,
  tooltipTrigger: PropTypes.bool,
};

Thumbnail.defaultProps = {
  disableTooltip: false,
  imgSize: 85,
  itemId: null,
  tooltipTrigger: false,
};

export default Thumbnail;
